import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const BeforeTreatmentPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section1_img = data.section1_img?.childImageSharp?.fluid
  const section1_background = data.section1_background?.childImageSharp?.fluid


  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/instructions/before-endodontic-treatment/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Before Endodontic Treatment Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Before Endodontic Treatment Indianapolis IN"/>
        <meta name="description" content="Indianapolis IN Endodontist Dr. Broady offers instruction for before endodontic treatment. 317-846-4980."/>
        <meta name="og:description" content="Indianapolis IN Endodontist Dr. Broady offers instruction for before endodontic treatment. 317-846-4980."/>
        <meta property="og:url" content="https://meridianendoindy.com/instructions/before-endodontic-treatment/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/instructions/before-endodontic-treatment/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Before Endodontic Treatment Indianapolis IN"/>
        <meta name="twitter:description" content="Indianapolis IN Endodontist Dr. Broady offers instruction for before endodontic treatment. 317-846-4980."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="before-endodontic-treatment-hero-section-bg hero-bg"
        fluid={hero_background}
        alt="Endodontics pc"
      >
      <div id="before-endodontic-treatment-hero-section">
        <Container>
        <div className="col-lg-12 doctors-container">
          <div className="row">
          <div className="col-lg-6 left-content">
            <h1 className="primary-text hero-heading">
              Before Endodontic Treatment
            </h1>
            <p className="hero-text">
              This procedure will be performed using local anesthesia. There are usually no restrictions after the procedure concerning driving or returning to work.
            </p>
            <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
          </div>
          <div className="col-lg-6">
            <Img fluid={hero_img} className="hero-img" alt="happy man"/>
          </div>
          </div>
          </div>
        </Container>
      </div>
    </BackgroundImage>
    <section id="faded-bg"></section>
  <section id="before-endodontic-treatment-section-1">
    <BackgroundImage
      Tag="div"
      className="before-endodontic-treatment-section1-bg"
      fluid={section1_background}
      alt="Endodontics pc"
    >
    <Container>
      <div className="col-lg-12">
        <h4> Before Endodontic Treatment </h4>
        <p>
          We want you to be comfortable during the treatment. Each procedure starts with local anesthesia. With no sedation, there's no need for a driver. Patients are fine to drive home, to work, or anywhere else they need to go following the appointment.
        </p>
        <p>
          However, patients who are sensitive to pain or experience high anxiety and panic attacks may request additional care during dental procedures. Nitrous oxide (laughing gas) and oral sedation are available to relax the patient prior to the root canal, retreatment, or surgery. Following treatment, another responsible person must be available to drive, or other arrangements must be made. Patients can't operate a motor vehicle or heavy machinery until the next day.
        </p>
        <p>
          Don't stop taking blood pressure, diabetes, thyroid, or other prescription medications before the procedure. For questions, contact our office at <a href="tel:3178464980">317-846-4980.</a>
        </p>
      </div>
      <div className="col-lg-12 container-box">
        <div className="row">
          <div className="col-lg-8 contents-left">
            <h5 className="primary-text">Please eat a full breakfast or lunch as applicable.</h5>
            <p>
              Eat a full breakfast or lunch, whichever comes first before your appointment. Eating and drinking will be limited for a few hours after your treatment.
            </p>
            <p>
              For patients requiring antibiotic premedication for heart conditions or artificial hips, knee, or other prostheses, please advise the office before your appointment, and take the medication on the day of your treatment.
            </p>
            <p>
              Although it's not required, you can take ibuprofen or naproxen sodium to reduce inflammation. For the best results, take two tablets two to four hours before endodontic therapy.
            </p>
            <p>
              For questions about preparing for your endodontic treatment, contact our office at <a href="tel:3178464980">317-846-4980.</a>
            </p>
          </div>
          <div className="col-lg-4 contents-right-img">
            <Img fluid={section1_img} className="section1-img"/>
          </div>
        </div>
      </div>
      <div id="contact-form-container">
        <ContactForm />
      </div>
    </Container>
    </BackgroundImage>
  </section>
    </Layout>
  )
}

export default BeforeTreatmentPage

export const query = graphql`
  query BeforeTreatmentPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "before-treatment-img" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_img: file(name: { eq: "before-endodontic-section1-img" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_background: file(name: { eq: "before-treatment-section1-bg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
